<template>
	<div class="inside-page-content">
		<div class="paper">
			<div class="page">
				<div class="top">
					<div class="item">
            <div class="img-box"><img :src="detail.pictureUrl" class="img" /></div>
            <div class="product">
              <div class="name">{{detail.unitName}}</div>
              <span class="class">{{types[detail.unitType]}}</span>
              <div class="at">{{location[detail.unitLocation]}}</div>
              <div class="desc">{{detail.introduction}}</div>
            </div>
          </div>
				</div>
				<div class="bar-title-text">
					<div class="bar-title">人才队伍</div>
					<div class="content" v-if="detail.teamList && detail.teamList.length">
						<div class="item" v-for="(t, i) in detail.teamList" :key="i">
							<div class="img-box"><img :src="t.pictureUrl" class="img" /></div>
							<div class="product">
								<div class="p-name">{{t.talentName}}</div>
								<div class="p-name">{{t.talentPosition}}</div>
							</div>
						</div>
					</div>
				</div>
				<div class="bar-title-text">
					<div class="bar-title">仪器资源</div>
					<div class="content" v-if="detail.instrumentList && detail.instrumentList.length">
						<div class="item item2"  v-for="(t, i) in detail.instrumentList" :key="i">
							<div class="img-box"><img :src="detail.pictureUrl" class="img" /></div>
							<div class="name2">{{t.instrumentName}}</div>
						</div>
					</div>
				</div>
				<div class="bar-title-text">
					<div class="bar-title">检验检测</div>
					<div class="content" v-if="detail.checkoutList && detail.checkoutList.length">
						<div class="item item2"  v-for="(t, i) in detail.checkoutList" :key="i">
							<div class="img-box"><img :src="t.pictureUrl" class="img" /></div>
							<div class="name2">{{t.checkoutName}}</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: 'fDetail',
		data() {
			return {
				id: {},
				detail: {},
				location: {
					1: '西南',
					2: '西北',
					3: '华中',
					4: '华东',
					5: '华南',
					6: '华北',
					7: '东北'
				},
				types: {
					1: '军工单位',
					2: '科研院所',
					3: '大型企业',
					4: '高等院校',
					5: '高新技术企业',
					6: '医疗机构',
					7: '检测机构',
					8: '军民融合企业',
					9: '政府机构',
					10: '民营企业'
				}
			}
		},
		created() {
			// 获取路由参数
			this.id = this.$route.query.id;
			this.getDetail();
		},
		mounted() {

		},
		methods: {
			getDetail() {
				this.$api.share.unitDetail(this.id).then((res) => {
					this.detail = res.data.data
				})
			}
		}
	}
</script>
<style scoped lang="less">
	.page {
		padding: 20px;
    min-width: 1100px;
    max-width: 1300px;
    margin: 0 auto;
		box-sizing: border-box;
		background: #fff;
		.top {
			display: flex;
			border-bottom: 1px solid #eee;
			// padding-bottom: 40px;
			margin-bottom: 40px;
		}
		.item {
				padding: 20px 10px;
				box-sizing: border-box;
				// border-bottom: 1px solid #ECECEC;
				display: flex;
				position: relative;
				.img-box {
						width: 200px;
						height: 138px;
						.img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}
				.product {
					flex: 1;
					padding: 0 130px 0 40px;
					.name {
						font-weight: 700;
						padding-bottom: 8px;
						font-size: 18px;
					}
					.desc {
						text-indent: 40px;
						font-size: 14px;
						height: 40px;
					}
					.class {
						background-color: #990000;
						color: #fff;
						font-size: 12px;
						padding: 3px 6px;
					}
					.at {
						padding: 8px 0;
						font-size: 12px;
					}
					.p-name {
						padding-top: 26px;
						font-weight: 700;
					}
				}
				.more {
					position: absolute;
					top: 50%;
					right: 20px;
					transform: translateY(-50%);
					background-color: #0046C0;
					color: #fff;
					padding: 5px 10px;
					border-radius: 6px;
					margin: 0 20px 20px;
					cursor: pointer;
					font-size: 14px;
				}
			}
			.item2 {
				width: 25%;
				padding: 10px 20px;
				box-sizing: border-box;
				flex-direction: column;
				text-align: center;
				.img-box {
					margin: 0 auto
				}
			}
	}
</style>
